import "xterm/dist/xterm.css";
import Terminal from "xterm/dist/xterm.js";

/**
 * Custom element that takes a single `messages` property (a list of strings) and it displays them in a x-term widget.
 */
export default class XTermCustomElement extends HTMLElement {
  constructor() {
    super();

    this.term = new Terminal({
      disableStdin: true,
      convertEol: true,
      scrollback: 9999999,
      theme: {
        background: "#091522",
        foreground: "#eeeeee",
      },
    });

    this.writtenMessages = [];
    this.term.resize(109, 30);
  }

  connectedCallback() {
    this.term.open(this);
  }

  disconnectedCallback() {
    this.term.dispose();
  }

  // Called whenever the `messages` property is set.
  set messages(newMessages) {
    const { type, messages } = diffMessages(this.writtenMessages, newMessages);

    // Clear previous messages
    if (type == "REPLACE") {
      this.term.clear();
    }

    // Write/Append new messages
    messages.forEach((message) => this.term.write(message + "\r\n"));

    // Remember which messages we've already written
    this.writtenMessages =
      type === "UPDATE" ? [...this.writtenMessages, ...messages] : messages;
  }
}

/** Finds whether we need to write new messages to the widget,
 * or clear the old ones and replace them with new ones.
 */
const diffMessages = (oldMessages, newMessages) => {
  if (oldMessages.length > newMessages.length) {
    return replace(newMessages);
  }

  for (let i = 0; i < oldMessages.length; i++) {
    const oldMessage = oldMessages[i];
    const newMessage = newMessages[i];

    if (oldMessage !== newMessage) {
      return replace(newMessages);
    }
  }

  const difference = newMessages.slice(oldMessages.length, newMessages.length);

  return {
    type: "UPDATE",
    messages: difference,
  };
};

/**
 * Little constructor helper to avoid repetition.
 */
const replace = (messages) => ({
  type: "REPLACE",
  messages,
});
