import { marked } from "marked";

export class MarkdownOutput extends HTMLElement {
  constructor() {
    super();
    this.container = document.createElement("div");
    this.container.className = "markdown-body";
    this.attachShadow({ mode: "open" });

    // Add styles for markdown content
    const style = document.createElement("style");
    style.textContent = `
      .markdown-body {
        padding: 1rem;
        line-height: 1.5;
        font-family: system-ui, -apple-system, sans-serif;
      }
      
      .markdown-body h1, .markdown-body h2, .markdown-body h3 {
        margin-top: 1.5em;
        margin-bottom: 0.5em;
      }
      
      .markdown-body p {
        margin: 0.5em 0;
      }
      
      .markdown-body code {
        background-color: #f6f8fa;
        padding: 0.2em 0.4em;
        border-radius: 3px;
        font-family: monospace;
      }
      
      .markdown-body pre {
        background-color: #f6f8fa;
        padding: 1em;
        border-radius: 6px;
        overflow-x: auto;
      }
      
      .markdown-body pre code {
        background-color: transparent;
        padding: 0;
      }
    `;

    this.shadowRoot.appendChild(style);
    this.shadowRoot.appendChild(this.container);
  }

  // Method to update content
  set value(markdown) {
    if (typeof markdown !== "string") return;

    // Convert markdown to HTML
    const html = marked(markdown, {
      breaks: true, // Convert line breaks to <br>
      gfm: true, // Enable GitHub Flavored Markdown
    });

    this.container.innerHTML = html;
  }

  // Get the current HTML content
  get value() {
    return this.container.innerHTML;
  }
}

// Register the custom element
customElements.define("markdown-output", MarkdownOutput);
