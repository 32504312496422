export class StripeCustomElement extends HTMLElement {
  constructor() {
    super();
    this._card = null;
    this._cardReady = false;
    this._stripe = window.Stripe(window.STRIPE_TOKEN);
    this._token = "";
    this._error = "";
    this._tokenData = {
      name: "",
      address_line1: "",
      address_line2: "",
      address_city: "",
      address_state: "",
      address_zip: "",
      address_country: "",
    };
  }

  get error() {
    return this._error;
  }

  get token() {
    return this._token;
  }

  get cardReady() {
    return this._cardReady;
  }

  set name(value) {
    this._tokenData.name = value;
  }
  set address1(value) {
    this._tokenData.address_line1 = value;
  }
  set address2(value) {
    this._tokenData.address_line2 = value;
  }
  set city(value) {
    this._tokenData.address_city = value;
  }
  set state(value) {
    this._tokenData.address_state = value;
  }
  set zip(value) {
    this._tokenData.address_zip = value;
  }
  set country(value) {
    this._tokenData.address_country = value;
  }

  set cardReady(value) {
    this._cardReady = value;
    if (this._cardReady && this._card) {
      this._stripe.createToken(this._card, this._tokenData).then((result) => {
        if (result.error) {
          this._error = result.error;
          this.dispatchEvent(new CustomEvent("tokenFailed"));
        } else {
          this._token = result.token;
          this.dispatchEvent(new CustomEvent("tokenSucceeded"));
        }
      });
    }
  }

  connectedCallback() {
    var elements = this._stripe.elements();

    var stripeContainerDiv = document.createElement("div");
    // The id is now only used for styling
    stripeContainerDiv.id = "card-element";

    this.appendChild(stripeContainerDiv);

    const card = elements.create("card", { hidePostalCode: true });
    card.mount(stripeContainerDiv);
    this._card = card;
  }
}
