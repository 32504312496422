// const COUNTRY_STATE_CITY_KEY;
const list_id = "address-state-list";

// type Province = {
//   id: number;
//   iso2: string;
//   name: string;
// };

// type AS_HTMLOptions = {
//   attrs: Map<string, string]>
// };

const createElement = (
  options /*: Maybe AS_HTMLOptions */,
  type /*: string */
) /*: HTMLInputElement */ => {
  let input = document.createElement(type);

  if (options && options.attrs) {
    options.attrs.forEach((value, key) => {
      input.setAttribute(key, value);
    });
  }

  return input;
};

/**
 * ProvinceElement implements a dynamic province/state/region field that, when
 * passed a Countries *alpha2Code*, performs a request to an API to retrieve
 * what's available for that country
 *
 * @fires provinceChanged
 * @fires provinceLoadFailed has event.detail.error:string added to the event object
 *
 */
export default class ProvinceSelectorElement extends HTMLElement {
  input; /*: HTMLInputElement */
  list; /*: HTMLDataListElement */
  data; /*: Map<string, [Province]> */
  _apiKey; /*: string */

  constructor() {
    super();

    this.input = createElement({}, "input");
    this.input.setAttribute("type", "text");
    this.input.addEventListener("change", this.onInputChange);
    // input list set after construction (see attributeChangedCallback)

    this.list = createElement({}, "datalist");
    // list id set after construction (see attributeChangedCallback)

    this.data = new Map();
    this._apiKey = window.COUNTRY_STATE_CITY_KEY;
  }

  static get observedAttributes() {
    return ["country", "prefix"];
  }

  onInputChange = (event /*: Event */) => {
    this.dispatchEvent(
      new CustomEvent("provinceChanged", {
        detail: event.target.value,
      })
    );
  };

  fetch() /*: void */ {
    // Check the cache
    if (this.data.get(this.getAttribute("country"))) {
      this.populateList();
      return;
    }

    let headers = new Headers();
    headers.append("X-CSCAPI-KEY", this._apiKey);
    let requestOptions /*: RequestInit */ = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    const sortFn = (a, b) => {
      if (a["name"].toLowerCase() < b["name"].toLowerCase()) {
        return -1;
      } else if (a["name"].toLowerCase() > b["name"].toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    };

    fetch(
      // https://countrystatecity.in/docs/api/states-by-country/#security
      "https://api.countrystatecity.in/v1/countries/" +
        this.getAttribute("country") +
        "/states",
      requestOptions
    )
      .then((response) => {
        response
          .json()
          .then((data) => {
            data.sort(sortFn);
            this.data.set(this.getAttribute("country"), data);
            this.populateList();
          })
          .catch((error) => {
            this.fetchFailed(error);
          });
      })
      .catch((error) => {
        this.fetchFailed(error);
      });
  }

  populateList() /*: void */ {
    this.data
      .get(this.getAttribute("country"))
      .forEach((province /*: Province */) => {
        let o = document.createElement("option");
        o.value = province.name;
        // o.label = province.iso2;
        this.list.appendChild(o);
      });
  }

  fetchFailed = (error /*: string */) /*: void */ => {
    this.dispatchEvent(new CustomEvent("provinceLoadFailed"), {
      detail: { error: error },
    });
  };

  removeListChidren() {
    let nodes = this.list.querySelectorAll("*");
    nodes.forEach((node) => {
      node.remove();
    });
  }

  // Lifecycle callbacks

  connectedCallback() {
    if (this.getAttribute("country")) {
      this.fetch();
    }
    // Getting this attribute from the parent (passed in by from Elm) must
    // happen here as the attribute doesn't appear to be populated in the
    // constructor of the class.

    // BTW, we're doing this because this autocomplete
    // attribute gets set differently depending on which address form is being
    // created so it can't be hardcoded
    this.input.setAttribute("autocomplete", this.getAttribute("autocomplete"));
    this.appendChild(this.input);
    this.appendChild(this.list);
  }

  attributeChangedCallback(
    name /*: string */,
    oldValue /*: string */,
    newValue /*: string */
  ) {
    switch (name) {
      case "prefix":
        // Datalists must have different ids, otherwise they share the same data
        this.input.setAttribute("list", newValue + "-" + list_id);
        this.list.setAttribute("id", newValue + "-" + list_id);
        break;
      case "country":
        if (this.getAttribute("country")) {
          // Remove the current listed items
          this.removeListChidren();
          this.fetch();
        }
        break;
      default:
    }
  }
}
