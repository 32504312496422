import "./MarkdownOutput.js";

export default class BreakingChangesElement extends HTMLElement {
  // API Args
  libraryName; /*: string */
  versionCurrent; /*: string */
  versionNew; /*: string */
  summarize; /*: boolean */
  pruneDirectory; /*: string */
  // internal
  socket; /*: WebSocket */
  statusOutput; /*: string */
  markdownOutput; /*: string */

  // Rather than observing the input attributes and reading them in
  // attributeChangedCallback(), we getAttribute() them in connectedCallback().
  // This makes it explicit that this element is configured only on
  // instantiation, and cannot be reused for multiple queries – if you want to
  // change any of the inputs, create a new instance instead.
  static observedAttributes = [];

  constructor() {
    super();
    // don't access attributes here!
    this.statusOutput = "";
    this.markdownOutput = "";
  }

  setupWS() /*: void */ {
    console.log("Setting up WebSocket connection");
    this.socket = new WebSocket("/sv/breaking-changes/ws");

    this.socket.addEventListener("open", () => {
      console.log("Connected to server");
      this.appendStatus("Ready to analyze");
      this.startAnalysis();
    });

    this.socket.addEventListener("message", (event) => {
      console.log("Message received:", event.data);
      try {
        const message = JSON.parse(event.data);
        if (message.type === "ping") {
          // Respond to ping with pong
          this.socket.send(JSON.stringify({ type: "pong" }));
        } else if (message.type === "analysis_update") {
          if (message.data.content_type == "stdout") {
            this.appendStatus(message.data.content);
          } else if (message.data.content_type == "stderr") {
            this.appendStatus(message.data.content);
          } else if (message.data.content_type == "markdown") {
            this.setMarkdown(message.data.content);
          } else {
            console.warn("Unknown analysis_update content_type: ", message.data.content_type);
            this.appendStatus(message.data.content);
          }
        } else if (message.type === "analysis_error") {
          this.appendStatus("Error: " + message.data.content);
        } else if (message.type === "analysis_complete") {
          this.appendStatus("Analysis complete");
        }
      } catch (e) {
        console.error("Error parsing message:", e);
        this.appendStatus("Error parsing server message");
      }
    });

    this.socket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
      this.appendStatus("Connection error");
    });

    this.socket.addEventListener("close", (event) => {
      console.log("Disconnected from server");
      console.error(event.reason);
      this.appendStatus("Disconnected");
    });

    console.log("WebSocket setup complete");
  }

  startAnalysis() {
    const message = {
      type: "start_analysis",
      data: {
        library_name: this.libraryName,
        version1: this.versionCurrent,
        version2: this.versionNew,
        summarize: this.summarize,
        prune_directory: this.pruneDirectory,
      },
    };
    this.socket.send(JSON.stringify(message));
    this.appendStatus("Analysis started...");
  }

  appendStatus(message /*: string */) /*: void */ {
    this.statusOutput += "\r\n" + message;
    this.dispatchEvent(new CustomEvent("statusUpdate", {
      detail: this.statusOutput,
    }));
  }

  setMarkdown(message /*: string */) /*: void */ {
    // currently don't expect to have incrementally-updated markdown, we should
    // just get one complete markdown blob
    this.markdownOutput = message;
    this.dispatchEvent(new CustomEvent("markdownReceived", {
      detail: this.markdownOutput,
    }));
  }

  // Lifecycle callbacks
  
  connectedCallback() {
    this.libraryName = this.getAttribute("library_name");
    this.versionCurrent = this.getAttribute("version_current");
    this.versionNew = this.getAttribute("version_new");
    this.summarize = this.getAttribute("summarize") || false;
    this.pruneDirectory = this.getAttribute("prune_directory") || ""; // Default to empty string if not set

    if (this.libraryName && this.versionCurrent && this.versionNew) {
      this.setupWS();
    }
  }

  disconnectedCallback() {
    if (this.socket) {
      this.socket.close();
    }
  }
}
